import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@seo'
import PageHeader from '@pageHeader'
import Footer from '@footer'
import FeaturedProjects from '@about/FeaturedProjects'
import WorkTemplateContent from '@works/WorkTemplateContent'
import { getFormattedWork } from '@helpers/SingleWork'
import { SingleWorkNode } from '@types'

import '@sass/components/works/WorkTemplate.scss'

type Props = {
  data: {
    contentfulPortfolioItem: SingleWorkNode
  }
}

const WorkTemplate: React.FC<Props> = ({ data }) => {
  const work = getFormattedWork(data)
  const { color, title, pageHeader } = work

  return (
    <>
      <Seo title={title} themeColor={color} description="" />
      <PageHeader
        title={{
          first: pageHeader.title.first,
          second: pageHeader.title.second,
        }}
        excerpt={{
          first: pageHeader.excerpt.first,
          second: pageHeader.excerpt.second,
        }}
        color={color}
      />
      <div className="column is-12 page single-work">
        <WorkTemplateContent data={work} />
        <FeaturedProjects currentProjectId={work.id} />
      </div>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query singleWorkQuery($id: String) {
    contentfulPortfolioItem(id: { eq: $id }) {
      id
      slug
      entryTitle
      url
      color
      tags
      pageHeaderFirst
      pageHeaderSecond
      excerptFIrst {
        excerptFIrst
      }
      excerptSecond {
        excerptSecond
      }
      rolePlayed
      resume {
        resume
      }
      mainImage {
        description
        file {
          url
        }
      }
      sliderImages {
        description
        file {
          url
        }
      }
    }
  }
`

export default WorkTemplate
